import { createRouter, createWebHistory } from "vue-router";
import AdminLogin from "../views/admin/Login.vue";
import Admin from "../views/admin/Home.vue";
import AdminDashboard from "../views/admin/Dashboard.vue";
import filesRoutes from "./files";
import SAdminRoutes from "./SAdminRoutes";
const routes = [
  {
    path: "/",
    name: "Home",
    component: AdminLogin,
  },
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    redirect: "/admin/dashboard",
    children: [...SAdminRoutes, ...filesRoutes],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
