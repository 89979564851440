<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span>Tenants <small>(200)</small></span
        ><router-link
          :to="{ name: 'AdminAddTenants' }"
          class="my-btn cw glow wd"
          @click="startAdd = true"
        >
          Add Tenant
        </router-link>
      </h1>

      <div class="my-table">
        <div class="table-filters">
          <fieldset>
            <legend>Type</legend>
            <div class="form-group">
              <select
                id="type"
                class="form-control"
                v-model="searchQuery.tenant_type"
              >
                <option value="">All Types</option>
                <option value="company">Company</option>
                <option value="individual">Individual</option>
                <option value="reseller">Reseller</option>
              </select>
            </div>
          </fieldset>
          <!-- wing -->
          <fieldset>
            <legend>Wing</legend>
            <div class="form-group">
              <select
                id="wing"
                class="form-control"
                v-model="searchQuery.wing_id"
              >
                <option value="all">All</option>
                <option
                  v-for="wing in wings"
                  :key="wing.wing_id"
                  :value="wing.wing_id"
                >
                  {{ wing.wing_name }}
                </option>
              </select>
            </div>
          </fieldset>
          <!-- status -->
          <fieldset>
            <legend>Status</legend>
            <div class="form-group">
              <select
                id="status"
                class="form-control"
                v-model="searchQuery.status"
              >
                <option value="">All Statuses</option>
                <option value="active">Active</option>
                <option value="on hold">On Hold</option>
                <option value="inactive">Unsubscribed</option>
              </select>
            </div>
          </fieldset>
          <!-- Search -->
          <fieldset>
            <legend>Search</legend>
            <div class="form-group">
              <input
                type="text"
                id="name"
                class="form-control"
                placeholder="Search"
                v-model="searchQuery.search"
              />
            </div>
          </fieldset>
          <!-- Filter -->
          <div class="form-group">
            <button class="my-btn cw wd glow">Filter</button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Subscriber</th>
              <th>Company</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Wing</th>
              <th>Subscriptions</th>
              <th>Tenant Type</th>
              <th>Status</th>
              <th>Joined at</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tenants" :key="item.id">
              <td>
                <img
                  :src="$upload(item.tenant_image)"
                  alt="Location Image"
                  @error="$imageurlalt()"
                />
              </td>
              <td>{{ item.tenant_name }}</td>
              <td>{{ item.company_name ? item.company_name : "-" }}</td>
              <td>{{ item.tenant_phone }}</td>
              <td>{{ item.tenant_email }}</td>
              <td>{{ item.wing.wing_name }}</td>
              <td>
                <span
                  v-for="(subscription, index) in item.subscriptions"
                  :key="subscription.id"
                  >{{ subscription.room.room_number
                  }}<span v-if="index < item.subscriptions.length - 1">,</span>
                  &nbsp;
                </span>
              </td>
              <td>{{ item.tenant_type }}</td>
              <td>
                <span :class="item.status">{{ item.status }}</span>
              </td>
              <td>{{ $date(item.created_at) }}</td>

              <td>
                <a-dropdown-button>
                  Actions
                  <template #overlay>
                    <a-menu>
                      <a-menu-item
                        key="1"
                        @click="
                          $router.push({
                            name: 'AdminAddTenants',
                            query: { id: item.tenant_id },
                          })
                        "
                        >Update Tenant</a-menu-item
                      >
                      <a-menu-item
                        key="2"
                        @click="
                          $router.push({
                            name: 'AdminViewTenant',
                            params: { id: item.tenant_id },
                          })
                        "
                        >View Tenant</a-menu-item
                      >
                      <a-menu-item
                        key="3"
                        @click="
                          selectedItem = item;
                          startDelete = true;
                        "
                        >Delete Tenant</a-menu-item
                      >
                    </a-menu>
                  </template>
                  <template #icon><i class="fa fa-angle-down"></i></template>
                </a-dropdown-button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>
      <confirmModel
        v-if="startDelete"
        @closeModel="startDelete = false"
        :modelTitle="'Archive Tenant'"
        :size="'sm'"
        :displayText="'Are you sure you want to move this tenant to archive?'"
        @confirmModel="deleteItem"
      />
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      tenants: [],
      newItem: {
        name: "",
        address: "",
        image: "",
      },
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      allItemsCount: 100,
      itemsPerpage: 10,
      pagesNumber: 0,
      currentPage: 1,
      searchQuery: {
        search: "",
        tenant_type: "",
        wing_id: "",
        status: "",
      },
      wings: [],
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_TENANTS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            house_id: this.$getHouseId(),
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.tenants = res.data.data.data;
              this.allItemsCount = res.data.data.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    createItem() {
      if (this.newItem.name && this.newItem.address && this.newItem.image) {
        this.$startLoader();
        this.$store
          .dispatch("CREATE_LOCATION", {
            data: this.newItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateItem() {
      if (this.selectedItem.name && this.selectedItem.address) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_LOCATION", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startEdit = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_LOCATION", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
    this.$getWings();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
      }
    }
  }
}

table {
  margin-top: 2rem;
}
.table-filters {
  display: flex;
  justify-content: flex-start;
  column-gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  fieldset {
    width: 200px;
    border-radius: 5px;
    border: 1px solid #ccc;
    legend {
      font-size: 0.7rem;
      font-weight: bold;
      margin: 0 10px;
      color: #666;
      font-weight: 400;
      padding: 0 10px;
    }
    .form-group {
      padding: 0 !important;
      margin: 0 !important;
      select,
      input {
        width: 100%;
        padding: 0.5rem;
        border: none;
        border-radius: 5px;
        margin-bottom: 0;
        font-size: 0.75rem;
      }
    }
  }
  button {
    height: 50px;
    width: 150px;
    display: inline-block;
    background-color: $primary_color;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1rem;
    &:hover {
      background-color: $secondary_color;
    }
  }
}
</style>