<template>
  <div class="ImagesViewer">
    <div>
      <div class="header">
        <h2>{{ iTitle }}</h2>
        <i class="fa fa-times" @click="closeMOdel"></i>
      </div>
      <div class="images-container">
        <img
          :src="$upload(image)"
          alt="image"
          @click="selectedImage = image"
          v-for="(image, index) in images"
          :key="index"
        />
      </div>
      <div class="selectedImage model" v-if="selectedImage">
        <i class="fa fa-times" @click="selectedImage = null"></i>
        <img :src="$upload(selectedImage)" alt="selectedImage" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImagesViewer",
  props: {
    images: {
      type: Array,
      required: true,
    },
    iTitle: {
      type: String,
      default: "View Images",
    },
  },
  data() {
    return {
      selectedImage: null,
    };
  },
  methods: {
    closeMOdel() {
      document.body.style.overflow = "auto";
      this.$emit("close");
    },
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.ImagesViewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  h2 {
    margin: 0;
    font-weight: 400;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: $primary_color;
  color: white;
  width: 80vw;
  margin-top: 1rem;
  border-radius: 10px 10px 0px 0px;
  i {
    cursor: pointer;
    font-size: 2rem;
  }
}
.images-container {
  display: flex;
  flex-direction: row;
  width: 80vw;
  background: #fff;
  height: 80vh;
  overflow-y: auto;
  border-radius: 0px 0px 10px 10px;
  img {
    width: 300px;
    height: 300px;
    margin: 10px;
    cursor: pointer;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 5px;
    display: inline-block;
  }
}
.selectedImage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  i {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 1rem;
    font-size: 2rem;
    color: white;
  }
  img {
    width: 80vw;
    height: 80vh;
    object-fit: contain;
  }
}
</style>