import { BACKEND } from "../variables.js";
import axios from "axios";
import rooms from "./rooms.js";
const GET_ROOM_CLASSES = BACKEND + "/roomCategory/getRoomCategoriesByHouseId";
const CREATE_ROOM_CLASS = BACKEND + "/roomCategory/createRoomCategory";
const UPDATE_ROOM_CLASS = BACKEND + "/roomCategory/updateRoomCategory";
const DELETE_ROOM_CLASS = BACKEND + "/roomCategory/deleteRoomCategory";

export default {
  state: {
    roomClasses: [],
  },
  getters: {},
  mutations: {},
  actions: {
    ["GET_ROOM_CLASSES"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ROOM_CLASSES + `?page=${payload.data.page}`,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["CREATE_ROOM_CLASS"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_ROOM_CLASS,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_ROOM_CLASS"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_ROOM_CLASS,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_ROOM_CLASS"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_ROOM_CLASS,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
};
