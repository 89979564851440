import { BACKEND } from "../variables.js";
import axios from "axios";
const GET_COMPLAINTS_BY_HOUSE_ID =
  BACKEND + "/complaints/GetAllComplaintsByHouse";
const GET_MY_COMPLAINTS = BACKEND + "/complaints/getMyComplaints";
const CREATE_COMPLAINT = BACKEND + "/complaints/addComplaint";
const UPDATE_COMPLAINT = BACKEND + "/complaints/updateComplaint";
const DELETE_COMPLAINT = BACKEND + "/complaints/deleteComplaint";

const GET_COMMENTS_BY_COMPLAINT =
  BACKEND + "/complaints/comments/getCommentsByComplaint";
const CREATE_COMPLAINT_COMMENT = BACKEND + "/complaints/comments/addComment";
const UPDATE_COMPLAINT_COMMENT = BACKEND + "/complaints/comments/updateComment";
const DELETE_COMPLAINT_COMMENT = BACKEND + "/complaints/comments/deleteComment";

const GET_ATTACHMENTS_BY_COMPLAINT =
  BACKEND + "/complaints/attachments/getAttachmentsByComplaint";
const CREATE_COMPLAINT_ATTACHMENT =
  BACKEND + "/complaints/attachments/addAttachment";
const UPDATE_COMPLAINT_ATTACHMENT =
  BACKEND + "/complaints/attachments/updateAttachment";
const DELETE_COMPLAINT_ATTACHMENT =
  BACKEND + "/complaints/attachments/deleteAttachment";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_COMPLAINTS_BY_HOUSE_ID"](state, payload) {
      return axios({
        method: "POST",
        url: GET_COMPLAINTS_BY_HOUSE_ID,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_MY_COMPLAINTS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_MY_COMPLAINTS + `?page=${payload.data.page}`,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["CREATE_COMPLAINT"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_COMPLAINT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_COMPLAINT"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_COMPLAINT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_COMPLAINT"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_COMPLAINT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },

    // Comments
    ["GET_COMMENTS_BY_COMPLAINT"](state, payload) {
      return axios({
        method: "POST",
        url: GET_COMMENTS_BY_COMPLAINT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["CREATE_COMPLAINT_COMMENT"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_COMPLAINT_COMMENT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_COMPLAINT_COMMENT"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_COMPLAINT_COMMENT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_COMPLAINT_COMMENT"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_COMPLAINT_COMMENT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },

    // Attachments
    ["GET_ATTACHMENTS_BY_COMPLAINT"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ATTACHMENTS_BY_COMPLAINT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["CREATE_COMPLAINT_ATTACHMENT"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_COMPLAINT_ATTACHMENT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_COMPLAINT_ATTACHMENT"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_COMPLAINT_ATTACHMENT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_COMPLAINT_ATTACHMENT"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_COMPLAINT_ATTACHMENT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
};
