import { BACKEND } from "../variables.js";
import axios from "axios";
const GET_BILLS_BY_HOUSE_URL = BACKEND + "/tenantBills/getBillsByHouse";
const GET_PENDING_BILLS_BY_HOUSE_URL =
  BACKEND + "/tenantBills/getUnpaidBillsByHouse";
const GET_BILLS_BY_TENANT_URL = BACKEND + "/tenantBills/getBillsByTenant";
const UPDATE_BILL_URL = BACKEND + "/tenantBills/update";
const SEND_REMINDER_URL = BACKEND + "/tenantBills/remind";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_BILLS_BY_HOUSE"](state, payload) {
      return axios({
        method: "POST",
        url: GET_BILLS_BY_HOUSE_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_PENDING_BILLS_BY_HOUSE"](state, payload) {
      return axios({
        method: "POST",
        url: GET_PENDING_BILLS_BY_HOUSE_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_BILLS_BY_TENANT"](state, payload) {
      return axios({
        method: "POST",
        url: GET_BILLS_BY_TENANT_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_BILL"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_BILL_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["SEND_REMINDER"](state, payload) {
      return axios({
        method: "POST",
        url: SEND_REMINDER_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
};
