<template>
  <div id="TopBar" v-if="$store.state.sitesettings">
    <div class="top-bar">
      <div class="right">
        <a :href="'tel:' + this.$store.state.sitesettings.site_phone">
          <i class="fas fa-phone-alt"></i>
          {{ this.$store.state.sitesettings.site_phone }}
        </a>
        <a :href="'mailto:' + this.$store.state.sitesettings.site_email">
          <i class="fas fa-envelope"></i>
          {{ this.$store.state.sitesettings.site_email }}
        </a>
      </div>
      <div class="left">
        <a
          :href="this.$store.state.sitesettings.site_facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fab fa-facebook-f"></i>
        </a>
        <a
          :href="this.$store.state.sitesettings.site_twitter"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fa-brands fa-x-twitter"></i>
        </a>
        <a
          :href="this.$store.state.sitesettings.site_instagram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fab fa-instagram"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
#TopBar {
  .left {
    i {
      margin-right: 5px;
      font-size: 20px;
    }
    @media (max-width: 900px) {
      display: none;
    }
  }
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: darken($color: $primary_color, $amount: 10%);
    color: #fff;
    padding: 10px 10rem;
    @media (max-width: 1500px) {
      padding: 10px 5rem;
    }
    @media (max-width: 1200px) {
      padding: 10px 4rem;
    }
    @media (max-width: 1000px) {
      padding: 10px 3rem;
    }
    @media (max-width: 750px) {
      padding: 10px 2rem;
    }
    @media (max-width: 500px) {
      padding: 10px 1rem;
    }
    a {
      color: #fff;
      text-decoration: none;
      margin: 0 10px;
      font-size: 12px;
      i {
        margin-right: 5px;
      }
    }
  }
}
</style>