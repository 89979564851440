import { BACKEND } from '../variables.js';
import axios from 'axios'
const GET_REQUESTS = BACKEND + '/requests/getRequests';
const CREATE_REQUEST = BACKEND + '/requests/createRequest';
const UPDATE_REQUEST = BACKEND + '/requests/updateRequest';
const DELETE_REQUEST = BACKEND + '/requests/deleteRequest';

export default {
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        ['GET_REQUESTS'](state, payload) {
            return {
                data: {
                    requests: {
                        data: [
                            {
                                id: 1,
                                leader: 'Ireme Technologies',
                                company: 'Ireme Technologies',
                                email: 'ireme@gmail.com',
                                phone: '08012345678',
                                type: 'Company',
                                rooms: [
                                    {
                                        id: 1,
                                        name: 'Room 1',
                                        description: 'Room 1',
                                        number: 'F1-01',
                                        price: 1000
                                    },
                                    {
                                        id: 2,
                                        name: 'Room 2',
                                        description: 'Room 2',
                                        number: 'F1-02',
                                        price: 2000
                                    },
                                    {
                                        id: 3,
                                        name: 'Room 3',
                                        description: 'Room 3',
                                        number: 'F1-03',
                                        price: 3000
                                    },
                                    {
                                        id: 4,
                                        name: 'Room 4',
                                        description: 'Room 4',
                                        number: 'F1-04',
                                        price: 4000
                                    },
                                    {
                                        id: 5,
                                        name: 'Room 5',
                                        description: 'Room 5',
                                        number: 'F1-05',
                                        price: 5000
                                    }
                                ],
                                wing: 'Wing A',
                                status: 'Pending',
                                joined_at: '2020-01-01',
                                image: 'logob.png',
                                subscription: {
                                    id: 1,
                                    plan: 'Monthly',
                                    status: 'Pending',
                                    start: '2020-01-01',
                                    end: '2020-12-31'
                                },
                            },
                            {
                                id: 2,
                                leader: 'Ireme Technologies',
                                company: 'Ireme Technologies',
                                email: 'ireme@gmail.com',
                                phone: '08012345678',
                                type: 'Reseller',
                                rooms: [
                                    {
                                        id: 1,
                                        name: 'Room 1',
                                        description: 'Room 1',
                                        number: 'F1-01',
                                        price: 1000
                                    },
                                    {
                                        id: 2,
                                        name: 'Room 2',
                                        description: 'Room 2',
                                        number: 'F1-02',
                                        price: 2000
                                    },
                                    {
                                        id: 3,
                                        name: 'Room 3',
                                        description: 'Room 3',
                                        number: 'F1-03',
                                        price: 3000
                                    },
                                    {
                                        id: 4,
                                        name: 'Room 4',
                                        description: 'Room 4',
                                        number: 'F1-04',
                                        price: 4000
                                    },
                                    {
                                        id: 5,
                                        name: 'Room 5',
                                        description: 'Room 5',
                                        number: 'F1-05',
                                        price: 5000
                                    }
                                ],
                                wing: 'Wing A',
                                status: 'Pending',
                                joined_at: '2020-01-01',
                                image: 'ireme.png',
                                subscription: {
                                    id: 1,
                                    plan: 'Yearly',
                                    status: 'Pending',
                                    start: '2020-01-01',
                                    end: '2020-12-31'
                                },
                            },
                            {
                                id: 3,
                                leader: 'Ireme Technologies',
                                company: 'Ireme Technologies',
                                email: 'ireme@gmail.com',
                                phone: '08012345678',
                                type: 'Individual',
                                rooms: [
                                    {
                                        id: 1,
                                        name: 'Room 1',
                                        description: 'Room 1',
                                        number: 'F1-01',
                                        price: 1000
                                    },
                                    {
                                        id: 2,
                                        name: 'Room 2',
                                        description: 'Room 2',
                                        number: 'F1-02',
                                        price: 2000
                                    },
                                    {
                                        id: 3,
                                        name: 'Room 3',
                                        description: 'Room 3',
                                        number: 'F1-03',
                                        price: 3000
                                    },
                                    {
                                        id: 4,
                                        name: 'Room 4',
                                        description: 'Room 4',
                                        number: 'F1-04',
                                        price: 4000
                                    },
                                    {
                                        id: 5,
                                        name: 'Room 5',
                                        description: 'Room 5',
                                        number: 'F1-05',
                                        price: 5000
                                    }
                                ],
                                wing: 'Wing A',
                                status: 'Pending',
                                joined_at: '2020-01-01',
                                image: 'logob.png',
                                subscription: {
                                    id: 1,
                                    plan: 'Daily',
                                    status: 'Pending',
                                    start: '2020-01-01',
                                    end: '2020-12-31'
                                },
                            },
                        ],
                        total: 1
                    }
                },
                status: 200
            }
        },
        ['CREATE_REQUEST'](state, payload) {
            return axios({
                method: 'POST',
                url: CREATE_REQUEST,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_REQUEST'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_REQUEST,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['DELETE_REQUEST'](state, payload) {
            return axios({
                method: 'POST',
                url: DELETE_REQUEST,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        }

    },
};
