
import axios from 'axios'
import { BACKEND } from '../variables';
const  GET_HOUSES_BY_ORG_ID_URL = BACKEND +'/house/getHousesByOrganizationId';
const  GET_MY_ORG_HOUSES_URL = BACKEND +'/house/getMyOrganizationHouses';
const  CREATE_HOUSE_URL = BACKEND +'/house/createHouse';

const houses = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_HOUSES_BY_ORG_ID']: (state, payload) => {
            return axios({
                url: GET_HOUSES_BY_ORG_ID_URL + `?page=${payload.data.page}`,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `${payload.token}`,
                }
            })
        },
        ['GET_MY_ORG_HOUSES']: (state, payload) => {
            return axios({
                url: GET_MY_ORG_HOUSES_URL + `?page=${payload.data.page}`,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `${payload.token}`,
                }
            })
        },
        ['CREATE_ORG_HOUSE']: (state, payload) => {
            return axios({
                url: CREATE_HOUSE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `${payload.token}`,
                }
            })
        },
    },
    modules: {},
}
export default houses;