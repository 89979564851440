<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span>{{ action }} Tenant</span>
        <p>Fill the form below to {{ action }} a new tenant.</p>
      </h1>
      <div class="admin-forms">
        <div class="input-group group-2">
          <div class="form-group">
            <label for="name">Name</label>
            <input
              type="text"
              id="leader"
              v-model="newItem.tenant_name"
              placeholder="Tenant Name"
            />
          </div>
          <div class="form-group">
            <label for="leader">Email</label>
            <input
              type="email"
              id="leader"
              v-model="newItem.tenant_email"
              placeholder="Tenant Email"
            />
          </div>
          <div class="form-group">
            <label for="leader">Phone</label>
            <input
              type="tel"
              id="leader"
              v-model="newItem.tenant_phone"
              placeholder="Tenant Phone"
            />
          </div>
          <div class="form-group">
            <label for="leader">Type</label>
            <select id="leader" v-model="newItem.tenant_type">
              <option value="">Select Type</option>
              <option value="individual">Individual</option>
              <option value="company">Company</option>
              <option value="reseller">Reseller</option>
            </select>
          </div>
        </div>

        <div class="input-group group-2">
          <div class="form-group">
            <label for="company">Company</label>
            <input
              type="text"
              id="company"
              v-model="newItem.company_name"
              placeholder="Company Name"
            />
          </div>
          <div class="form-group">
            <label for="email">Company Email</label>
            <input
              type="email"
              id="email"
              v-model="newItem.company_email"
              placeholder="Company Email"
            />
          </div>
          <div class="form-group">
            <label for="phone">Company Phone</label>
            <input
              type="text"
              id="phone"
              v-model="newItem.company_phone"
              placeholder="Company Phone"
            />
          </div>
          <div class="form-group">
            <label for="wing">Main Wing</label>
            <select id="wing" v-model="newItem.main_wing">
              <option value="">Select Wing</option>
              <option
                v-for="wing in wings"
                :value="wing.wing_id"
                :key="wing.wing_id"
              >
                {{ wing.wing_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for="image">Image</label>
          <o-upload
            :multiple="false"
            @imagesUploaded="
              (data) => {
                newItem.tenant_image = data;
              }
            "
            @imagesUploadedFailed="
              (data) => {
                console.log('failed');
              }
            "
            :value="newItem.tenant_image"
            @startLoader="() => $startLoader()"
            @stopLoader="() => $stopLoader()"
          />
        </div>
        <button @click="createItem" v-if="action == 'Add'">Add Tenant</button>
        <button @click="updateItem" v-if="action == 'Edit'">
          Update Tenant
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      wings: [],
      newItem: {
        tenant_name: "",
        tenant_email: "",
        tenant_phone: "",
        tenant_type: "",
        company_name: "",
        company_email: "",
        company_phone: "",
        main_wing: "",
        tenant_image: "",
        house_id: null,
      },
      startAdd: false,
      selected: null,
      tenant_id: null,
      action: "Add",
    };
  },
  methods: {
    createItem() {
      if (
        this.newItem.tenant_name &&
        this.newItem.tenant_email &&
        this.newItem.tenant_type &&
        this.newItem.main_wing
      ) {
        this.$startLoader();
        this.newItem.house_id = this.$getHouseId();
        this.$store
          .dispatch("CREATE_TENANT", {
            data: this.newItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.$router.push({
                  name: "AdminViewTenant",
                  params: { id: res.data.tenant.tenant_id },
                  query: { tab: "subscriptions" },
                });
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    getWings() {
      this.$startLoader();
      this.$store
        .dispatch("GET_WINGS", {
          token: this.$getAdminToken(),
          data: {
            house_id: this.$getHouseId(),
            paginate: 10,
            page: this.currentPage,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            this.wings = res.data.wings.data;
            this.$stopLoader();
          } else {
            toast.error(res.data.message);
            this.$stopLoader();
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getTenantById() {
      this.$startLoader();
      this.$store
        .dispatch("GET_TENANT_BY_ID", {
          token: this.$getAdminToken(),
          data: {
            tenant_id: this.$route.query.id,
            house_id: this.$getHouseId(),
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.newItem = res.data;
            this.$stopLoader();
          } else {
            toast.error(res.data.message);
            this.$stopLoader();
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    updateItem() {
      if (
        this.newItem.tenant_name &&
        this.newItem.tenant_email &&
        this.newItem.tenant_type &&
        this.newItem.main_wing
      ) {
        this.$startLoader();
        this.newItem.house_id = this.$getHouseId();
        this.$store
          .dispatch("UPDATE_TENANT", {
            data: this.newItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.$router.push({
                  name: "AdminTenants",
                });
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
  },
  mounted() {
    this.getWings();
    if (this.$route.query.id && this.$route.query.id != "") {
      this.getTenantById();
      this.action = "Edit";
      this.tenant_id = this.$route.query.id;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 1rem;
    p {
      font-size: 14px;
      color: #686868;
      font-weight: 400;
    }
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
      }
    }
  }
}
</style>