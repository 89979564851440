import AdminFiles from '../views/admin/Files/AdminFiles.vue'
import AdminViewFolder from '../views/admin/Files/AdminViewFolder.vue'
const filesRoutes = [
    {
        path: '/admin/files-management',
        name: 'AdminFiles',
        component: AdminFiles
    },
    {
        path: '/admin/view-folder/:id/:name',
        name: 'AdminViewFolder',
        component: AdminViewFolder
    }
]
export default filesRoutes
