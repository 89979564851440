import { createStore } from "vuex";
import { UPLOAD_URL } from "./variables.js";
import admins from "./modules/admin.js";
import tenants from "./modules/tenants.js";
import files from "./modules/files.js";
import employees from "./modules/employees.js";
import bookingrequests from "./modules/bookingrequests.js";
import cancellationrequests from "./modules/cancellationrequests.js";
import floors from "./modules/floors.js";
import wings from "./modules/wings.js";
import rooms from "./modules/rooms.js";
import roomClasses from "./modules/roomClasses.js";
import houses from "./modules/houses.js";
import organizations from "./modules/organizations.js";
import bills from "./modules/bills.js";
import subscriptions from "./modules/subscriptions.js";
import complaints from "./modules/complaints.js";

export default createStore({
  state: {
    startLoader: false,
    showSANav: true,
    activePage: null,
    apiUploadUrl: UPLOAD_URL,
    sitesettings: null,
    equipments: [
      "Fan",
      "Router",
      "Table",
      "Chair",
      "CCTV",
      "AC",
      "TV",
      "Projector",
      "Whiteboard",
      "Printer",
      "Scanner",
      "Telephone",
      "Computer",
      "UPS",
      "Generator",
      "Refrigerator",
      "Microwave",
      "Water Heater",
      "Water Dispenser",
      "Coffee Machine",
      "Tea Machine",
      "Sofa",
      "Curtain",
      "Washing Machine",
      "Dishwasher",
      "Oven",
      "Cooking Range",
      "Iron",
      "Iron Board",
      "Dining Table",
      "Bed",
      "Wardrobe",
      "Dressing Table",
      "Mirror",
      "Shower",
      "Bathtub",
      "Toilet",
      "Bidet",
      "Sink",
      "Towel Rack",
      "Soap Holder",
      "Shampoo Holder",
      "Toilet Paper Holder",
      "Towel",
      "Bed Sheet",
      "Pillow",
      "Blanket",
      "Curtain",
      "Cushion",
      "Carpet",
      "Mat",
      "Clock",
      "Painting",
      "Plant",
      "Flower Vase",
      "Bookshelf",
      "Book",
      "Magazine",
      "Newspaper",
      "Cupboard",
      "Drawer",
      "Shelf",
      "Rack",
      "Basket",
      "Bucket",
      "Mop",
      "Broom",
      "Dustbin",
      "Dustpan",
      "Brush",
      "Scrubber",
      "Gloves",
      "Mask",
      "Sanitizer",
      "Disinfectant",
      "Air Freshener",
      "Insect Killer",
      "First Aid Box",
      "Fire Extinguisher",
      "Smoke Detector",
      "Fire Alarm",
      "Fire Sprinkler",
      "Emergency Light",
      "Exit Sign",
      "Fire Hose",
      "Fire Hydrant",
      "Fire Pump",
      "Fire Blanket",
      "Fire Bucket",
      "Fire Axe",
      "Fire Alarm Bell",
      "Fire Alarm Button",
      "Fire Alarm Panel",
      "Fire Alarm Siren",
      "Fire Alarm Strobe",
      "Fire Alarm Horn",
      "Fire Alarm Light",
      "Fire Alarm Sounder",
      "Fire Alarm Speaker",
      "Fire Alarm Detector",
      "Fire Alarm Sensor",
      "Fire Alarm Transmitter",
      "Fire Alarm Receiver",
      "Fire Alarm Controller",
      "Fire Alarm Monitor",
      "Fire Alarm Logger",
      "Fire Alarm Printer",
      "Fire Alarm Server",
      "Fire Alarm Client",
      "Fire Alarm Gateway",
      "Fire Alarm Router",
      "Fire Alarm Switch",
      "Fire Alarm Hub",
      "Fire Alarm Modem",
      "Fire Alarm Access Point",
      "Fire Alarm Repeater",
      "Fire Alarm Extender",
      "Fire Alarm Amplifier",
      "Fire Alarm Antenna",
      "Fire Alarm Cable",
      "Fire Alarm Wire",
      "Fire Alarm Fiber",
      "Fire Alarm Connector",
      "Fire Alarm Adapter",
      "Fire Alarm Coupler",
      "Fire Alarm Splitter",
      "Fire Alarm Combiner",
      "Fire Alarm Converter",
      "Fire Alarm Transceiver",
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    admins,
    tenants,
    files,
    employees,
    bookingrequests,
    cancellationrequests,
    floors,
    wings,
    rooms,
    roomClasses,
    houses,
    organizations,
    bills,
    subscriptions,
    complaints,
  },
});
