<template>
  <div class="Model">
    <div
      class="model-dialog"
      :class="{
        active: dialogActive,
        md: size == 'md',
        sm: size == 'sm',
        lg: size == 'lg',
      }"
      v-click-out-side="customMethod2"
    >
      <div class="model-content">
        <div class="model-header">
          <label for="" v-if="modelTitle">{{ modelTitle }}</label>
          <label for="" v-else></label>
          <button class="close" @click="closeModel()">&times;</button>
        </div>
        <div class="model-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
export default {
  name: "Model",
  props: {
    modelTitle: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "md",
    },
  },
  data() {
    return {
      dialogActive: false,
      clickCount: 0,
      modelOpened: false,
    };
  },
  directives: {
    clickOutSide,
  },
  methods: {
    customMethod2() {
      this.clickCount++;
      if (this.clickCount > 2) {
        // this.closeModel();
      }
    },
    closeModel() {
      this.dialogActive = false;
      this.modelOpened = false;
      setTimeout(() => {
        document.body.style.overflowY = "auto";
        this.$emit("closeModel");
      }, 300);
    },
  },
  mounted() {
    setTimeout(() => {
      this.dialogActive = true;
      this.modelOpened = true;
    }, 10);
    document.body.style.overflow = "hidden";
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.Model {
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00000079;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  .model-dialog {
    background-color: #fefefe;
    border: 1px solid #888;
    width: 50%;
    border-radius: 30px;
    margin: 5% auto;
    transition: all 0.5s ease-in-out;
    transform: scale(0, 0);
    height: fit-content;
    @media screen and (max-width: 1500px) {
      width: 50%;
      margin: 2% 5%;
    }
    @media screen and (max-width: 1100px) {
      width: 70%;
    }
    @media screen and (max-width: 850px) {
      width: 100%;
      margin: 0;
      border-radius: 0px;
      overflow-y: hidden;
      height: 100vh;
    }
    &.active {
      transform: scale(1, 1);
    }

    .model-content {
      padding: 10px 20px;
      @media screen and (max-width: 850px) {
        padding: 10px 0px;
      }
      .model-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 850px) {
          padding: 0px 10px;
        }
        label {
          font-size: 24px;
          font-weight: 600;
          color: #333333;
          margin-bottom: 10px;
        }
        .close {
          color: #aaaaaa;
          font-size: 28px;
          font-weight: bold;
          &:hover,
          &:focus {
            color: #000;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
      .model-body {
        margin-bottom: 30px;
      }
      .model-footer {
        display: flex;
        justify-content: flex-end;
        button {
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          margin-left: 10px;
          cursor: pointer;
          &.submit {
            background-color: $primary_color;
            color: #ffffff;
            &:hover {
              background-color: #000000;
            }
          }
          &.close {
            background-color: #562020;
            color: #ffffff;
          }
        }
      }
    }
  }
  .lg {
    &.model-dialog {
      width: 80%;
    }
  }
  .sm {
    &.model-dialog {
      width: 30%;
    }
  }
}

@media screen and (max-width: 768px) {
  .top-bar {
    flex-direction: column;
    align-items: flex-start;
    .left,
    .right {
      margin-bottom: 10px;
      p {
        margin-right: 0;
      }
    }
    .right {
      .a {
        margin-right: 0;
      }
    }
  }
  .navbar {
    height: auto;
    .navbar-item {
      flex-wrap: wrap;
      .a {
        width: 100%;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .top-bar {
    .left,
    .right {
      p {
        font-size: 10px;
      }
    }
  }
}
</style>