<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span
          >Floors <small>({{ floors.length }})</small></span
        ><a href="" class="my-btn cw glow wd" @click.prevent="startAdd = true">
          Add Floor
        </a>
      </h1>
      <div class="my-table">
        <form class="table-filters">
          <!-- Wing -->
          <fieldset>
            <legend>Wing</legend>
            <div class="form-group">
              <select
                id="wing"
                class="form-control"
                v-model="searchQuery.wing_id"
              >
                <option value="all">All</option>
                <option
                  v-for="wing in wings"
                  :key="wing.wing_id"
                  :value="wing.wing_id"
                >
                  {{ wing.wing_name }}
                </option>
              </select>
            </div>
          </fieldset>
          <!-- Search -->
          <fieldset>
            <legend>Search</legend>
            <div class="form-group">
              <input
                type="text"
                id="name"
                class="form-control"
                placeholder="Search"
                v-model="searchQuery.search"
              />
            </div>
          </fieldset>
          <!-- Filter -->
          <div class="form-group">
            <button class="my-btn cw wd glow" @click.prevent="getItems()">
              Filter
            </button>
          </div>
        </form>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Wing</th>
              <th>Rooms</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in floors" :key="item.floor_id">
              <td>
                {{ index + 1 }}
              </td>
              <td>{{ item.floor_name }}</td>
              <td v-if="item.wing">{{ item.wing.wing_name }}</td>
              <td v-else>-</td>
              <td>{{ item.rooms.length }}</td>

              <td>
                <button
                  @click="
                    selectedItem = item;
                    startEdit = true;
                  "
                  class="btn edit"
                >
                  Edit
                </button>
                <button
                  @click="
                    selectedItem = item;
                    startDelete = true;
                  "
                  class="btn delete"
                >
                  Archive
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>
      <Modal
        :title="'Delete Floor'"
        :size="'sm'"
        v-model:open="startDelete"
        @closeModel="startDelete = false"
        centered
        :okText="'Delete'"
        @ok="deleteItem()"
        width="400px"
      >
        <p>Are you sure you want to delete this floor?</p>
      </Modal>
      <Model
        :modelTitle="'Add Floor'"
        :size="'sm'"
        v-if="startAdd"
        @closeModel="startAdd = false"
      >
        <div class="form-group">
          <div class="input-group">
            <label for="name">Name</label>
            <input
              type="text"
              id="name"
              v-model="newItem.floor_name"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <label for="wing">Wing</label>
            <select id="wing" v-model="newItem.wing_id" class="form-control">
              <option value="">Select Wing</option>
              <option
                v-for="wing in wings"
                :key="wing.wing_id"
                :value="wing.wing_id"
              >
                {{ wing.wing_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="btns">
          <button @click="startAdd = false" class="my-btn cw wd cancel">
            Cancel
          </button>
          <button @click="createItem" class="my-btn cw wd glow">Save</button>
        </div>
      </Model>
      <Model
        :modelTitle="'Edit Floor'"
        :size="'sm'"
        v-if="startEdit"
        @closeModel="startEdit = false"
      >
        <div class="form-group">
          <div class="input-group">
            <label for="name">Name</label>
            <input
              type="text"
              id="name"
              v-model="selectedItem.floor_name"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <label for="wing">Wing</label>
            <select
              id="wing"
              v-model="selectedItem.wing_id"
              class="form-control"
            >
              <option value="">Select Wing</option>
              <option
                v-for="wing in wings"
                :key="wing.wing_id"
                :value="wing.wing_id"
              >
                {{ wing.wing_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="btns">
          <button @click="startEdit = false" class="my-btn cw wd cancel">
            Cancel
          </button>
          <button @click="updateItem" class="my-btn cw wd glow">Save</button>
        </div>
      </Model>
    </div>
  </div>
</template>
  
  <script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      floors: [],
      newItem: {
        floor_name: "",
        wing_id: "",
      },
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      allItemsCount: 1,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      wings: [],
      admins: [],
      searchQuery: {
        wing_id: "all",
        search: "",
      },
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_FLOORS_BY_HOUSE_ID", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            house_id: this.$getHouseId(),
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.floors = res.data.floors.data;
              this.allItemsCount = res.data.floors.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    createItem() {
      if (this.newItem.floor_name && this.newItem.wing_id) {
        this.$startLoader();
        this.$store
          .dispatch("CREATE_FLOOR", {
            data: {
              ...this.newItem,
              organization_id: this.$getOrganizationId(),
              house_id: this.$getHouseId(),
            },
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateItem() {
      if (this.selectedItem.floor_name && this.selectedItem.wing_id) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_FLOOR", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startEdit = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_FLOOR", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.$getWings();
    this.$getAdminsByOrg();
    this.getItems();
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
      }
    }
  }
}

table {
  margin-top: 2rem;
}
.table-filters {
  display: flex;
  justify-content: flex-start;
  column-gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  fieldset {
    width: 200px;
    border-radius: 5px;
    border: 1px solid #ccc;
    legend {
      font-size: 0.7rem;
      font-weight: bold;
      margin: 0 10px;
      color: #666;
      font-weight: 400;
      padding: 0 10px;
    }
    .form-group {
      padding: 0 !important;
      margin: 0 !important;
      select,
      input {
        width: 100%;
        padding: 0.5rem;
        border: none;
        border-radius: 5px;
        margin-bottom: 0;
        font-size: 0.75rem;
      }
    }
  }
  button {
    height: 50px;
    width: 150px;
    display: inline-block;
    background-color: $primary_color;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1rem;
    &:hover {
      background-color: $secondary_color;
    }
  }
}
</style>