<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span>{{ folderName }}</span>
        <v-menu transition="slide-x-transition">
          <template v-slot:activator="{ props }">
            <button v-bind="props"><i class="fas fa-plus"></i> Add New</button>
          </template>

          <div class="drop-content">
            <span> <i class="fas fa-file-upload"></i> Upload File </span>
            <span>
              <i class="fas fa-folder-plus"></i>
              Create Folder
            </span>
          </div>
        </v-menu>
      </h1>
      <div class="files" :class="displayModel">
        <div class="search-section">
          <input type="text" placeholder="Search" />
          <div class="displayModel">
            <button
              class="button"
              :class="{ active: displayModel == 'list' }"
              @click="displayModel = 'list'"
            >
              <i class="fas fa-list"></i>
            </button>
            <button
              class="button"
              :class="{ active: displayModel == 'grid' }"
              @click="displayModel = 'grid'"
            >
              <i class="fas fa-th"></i>
            </button>
          </div>
        </div>
        <div class="files-header">
          <span>Name</span>
          <span>Type</span>
          <span>Size</span>
          <span>Date</span>
        </div>
        <div class="files-body">
          <div class="file goback" @click="goBack">
            <span>
              <img :src="$img('folder.png')" alt="file" /><label
                >...</label
              ></span
            >
          </div>
          <div
            class="file"
            v-for="(file, index) in sortedFiles(files)"
            :key="index"
            :class="file.type"
          >
            <span>
              <img
                :src="$img($fiesIcons(file.type, file.name))"
                alt="file"
              /><label>{{ file.name }}</label></span
            >
            <span class="smalls">{{ file.type }}</span>
            <span class="smalls" v-if="file.type == 'file'">{{
              file.size
            }}</span>
            <span class="smalls" v-else>{{ file.size }} items</span>
            <span class="smalls">{{ file.date }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      files: [
        {
          name: "Management Structure.pdf",
          type: "file",
          size: "1.5MB",
          date: "2021-10-10",
        },
        {
          name: "Approval Letter.docx",
          type: "file",
          size: "2.5MB",
          date: "2021-10-10",
        },
        {
          name: "Zoom Minutes For 2024 meeting.ppt",
          type: "file",
          size: "2.5MB",
          date: "2021-10-10",
        },
        {
          name: "List of candidates.xlsx",
          type: "file",
          size: "2.5MB",
          date: "2021-10-10",
        },
        {
          name: "All website photos 2023-2024.rar",
          type: "file",
          size: "2.5MB",
          date: "2021-10-10",
        },
        {
          name: "Company draft.txt",
          type: "file",
          size: "2.5MB",
          date: "2021-10-10",
        },
        {
          name: "Contacts",
          type: "folder",
          size: 2,
          date: "2021-10-10",
        },
        {
          name: "Projects",
          type: "folder",
          size: 5,
          date: "2021-10-10",
        },
        {
          name: "Photos",
          type: "folder",
          size: 10,
          date: "2021-10-10",
        },
      ],
      displayModel: "list",
      folderName: "",
      folderId: "",
      activeFolder: {
        name: "Contacts",
        id: 1,
        parent: null,
      },
    };
  },
  methods: {
    goBack() {
      if (this.activeFolder.parent) {
        this.$router.push({
          name: "AdminViewFolder",
          params: {
            name: this.activeFolder.parent.name,
            id: this.activeFolder.parent.id,
          },
        });
      } else {
        this.$router.push({ name: "AdminFiles" });
      }
    },
  },
  mounted() {
    this.folderName = this.$route.params.name;
    this.folderId = this.$route.params.id;
  },
  computed: {
    sortedFiles() {
      return (files) => {
        // sort files by type and name
        return files.sort((a, b) => {
          if (a.type > b.type) {
            return -1;
          }
          if (a.type < b.type) {
            return 1;
          }
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    padding: 0 2rem;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button,
    .button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
}
.drop-content {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 1rem;
  z-index: 100;
  span {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    i {
      margin-right: 0.5rem;
    }
    &:hover {
      cursor: pointer;
      color: $primary_color;
    }
  }
}
</style>