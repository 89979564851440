import { BACKEND } from "../variables.js";
import axios from "axios";
const GET_EMPLOYEES = BACKEND + "/employees/getEmployees";
const CREATE_EMPLOYEE = BACKEND + "/employees/createEmployee";
const UPDATE_EMPLOYEE = BACKEND + "/employees/updateEmployee";
const DELETE_EMPLOYEE = BACKEND + "/employees/deleteEmployee";

export default {
  state: {
    employees: {
      data: [
        {
          id: 1,
          name: "Mugisha Aime",
          email: "mugisha2gmail.com",
          phone: "0781234567",
          role: "Director",
          status: "Active",
          joined_at: "2020-01-01",
          approved_by: {
            id: 1,
            name: "Admin",
            email: "admin1@gmail.com",
          },
        },
      ],
      total: 1,
    },
  },
  getters: {},
  mutations: {},
  actions: {
    ["GET_EMPLOYEES"](state, payload) {
      // return axios({
      //     method: 'POST',
      //     url: GET_EMPLOYEES + `?page=${payload.data.page}`,
      //     data: payload.data,
      //     headers: {
      //         Authorization: payload.token
      //     }
      // })
      return {
        data: {
          employees: {
            data: [
              {
                id: 1,
                name: "Mugisha Aime",
                email: "mugisha2gmail.com",
                phone: "0781234567",
                role: "Director",
                status: "Active",
                joined_at: "2020-01-01",
                approved_by: {
                  id: 1,
                  name: "Admin",
                  email: "admin1@gmail.com",
                },
              },
              {
                id: 2,
                name: "Kalisa Erneste",
                email: "mugisha2gmail.com",
                phone: "0781234567",
                role: "Director",
                status: "Active",
                joined_at: "2020-01-01",
                approved_by: {
                  id: 1,
                  name: "Admin",
                  email: "admin1@gmail.com",
                },
              },
              {
                id: 3,
                name: "Mugisha Aime",
                email: "mugisha2gmail.com",
                phone: "0781234567",
                role: "Director",
                status: "Active",
                joined_at: "2020-01-01",
                approved_by: {
                  id: 1,
                  name: "Admin",
                  email: "admin1@gmail.com",
                },
              },
            ],
            total: 1,
          },
        },
        status: 200,
      };
    },
    ["CREATE_EMPLOYEE"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_EMPLOYEE,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_EMPLOYEE"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_EMPLOYEE,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_EMPLOYEE"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_EMPLOYEE,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
};
