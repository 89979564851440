import { BACKEND } from '../variables.js';
import axios from 'axios'
import floors from './floors.js';
const GET_WINGS = BACKEND + '/wing/getWingsByHouseId';
const CREATE_WING = BACKEND + '/wing/createWing';
const UPDATE_WING = BACKEND + '/wing/updateWing';
const DELETE_WING = BACKEND + '/wing/deleteWing';

export default {
    state: {
        wings: [],
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        ['GET_WINGS'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_WINGS + `?page=${payload.data.page}`,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['CREATE_WING'](state, payload) {
            return axios({
                method: 'POST',
                url: CREATE_WING,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_WING'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_WING,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['DELETE_WING'](state, payload) {
            return axios({
                method: 'POST',
                url: DELETE_WING,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        }

    },
};
