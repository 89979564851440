import { BACKEND } from "../variables.js";
import axios from "axios";
const GET_ORGANIZATIONS = BACKEND + "/organization/getAllOrganizations";
const CREATE_ORGANIZATION = BACKEND + "/organization/createOrganization";
const UPDATE_ORGANIZATION = BACKEND + "/organization/updateOrganization";
const DELETE_ORGANIZATION = BACKEND + "/organization/deleteOrganization";

export default {
  state: {
    wings: [],
  },
  getters: {},
  mutations: {},
  actions: {
    ["GET_ORGANIZATIONS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ORGANIZATIONS + `?page=${payload.data.page}`,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["CREATE_ORGANIZATION"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_ORGANIZATION,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_ORGANIZATION"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_ORGANIZATION,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_ORGANIZATION"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_ORGANIZATION,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
};
