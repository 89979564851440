<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span>Cancellation Requests <small>(3)</small></span>
      </h1>

      <div class="my-table">
        <div class="table-filters">
          <fieldset>
            <legend>Type</legend>
            <div class="form-group">
              <select id="type" class="form-control">
                <option value="all">All Types</option>
                <option value="company">Company</option>
                <option value="individual">Individual</option>
                <option value="individual">Reseller</option>
              </select>
            </div>
          </fieldset>
          <!-- wing -->
          <fieldset>
            <legend>Wing</legend>
            <div class="form-group">
              <select id="wing" class="form-control">
                <option value="all">All Wings</option>
                <option value="wing1">Wing 1</option>
                <option value="wing2">Wing 2</option>
                <option value="wing3">Wing 3</option>
              </select>
            </div>
          </fieldset>
          <!-- Search -->
          <fieldset>
            <legend>Search</legend>
            <div class="form-group">
              <input
                type="text"
                id="name"
                class="form-control"
                placeholder="Search"
              />
            </div>
          </fieldset>
          <!-- Filter -->
          <div class="form-group">
            <button class="my-btn cw wd glow">Filter</button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Subscriber</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Room number(s)</th>
              <th>Status</th>
              <th>Requested at</th>
              <th>Approved by</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in allItems" :key="item.id">
              <td>
                {{ index + 1 }}
              </td>
              <td v-if="item.tenant.tenant_type != 'company'">
                {{ item.tenant.tenant_name }}
              </td>
              <td v-else>{{ item.tenant.company_name }}</td>
              <td>{{ item.tenant.tenant_phone }}</td>
              <td>{{ item.tenant.tenant_email }}</td>
              <td>
                <span
                  v-for="(subscription, index) in item.subscriptionsData"
                  :key="subscription.id"
                  >{{ subscription.room.room_number
                  }}<span v-if="index < item.subscriptionsData.length - 1"
                    >,</span
                  >
                  &nbsp;
                </span>
              </td>
              <td>
                <span :class="'chip ' + item.status">{{ item.status }}</span>
              </td>
              <td>{{ $date(item.created_at) }}</td>
              <td v-if="item.approved_by">{{ item.approved_by.name }}</td>
              <td v-else>-</td>

              <td>
                <a-dropdown-button>
                  Actions
                  <template #overlay>
                    <a-menu>
                      <a-menu-item
                        key="1"
                        @click="
                          selectedItem = item;
                          startApprove = true;
                        "
                        >Approve</a-menu-item
                      >
                      <a-menu-item
                        key="2"
                        @click="
                          selectedItem = item;
                          startReject = true;
                        "
                        >Reject</a-menu-item
                      >
                      <a-menu-item
                        key="3"
                        @click="
                          selectedItem = item;
                          startDelete = true;
                        "
                        >Delete</a-menu-item
                      >
                    </a-menu>
                  </template>
                  <template #icon><i class="fa fa-angle-down"></i></template>
                </a-dropdown-button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>
      <Modal
        :title="'Approve Request'"
        :size="'sm'"
        v-model:open="startApprove"
        @closeModel="startApprove = false"
        centered
        :okText="'Approve'"
        @ok="approveItem()"
        width="400px"
      >
        <p>Are you sure you want to approve this request?</p>
      </Modal>
      <Modal
        :title="'Reject Request'"
        :size="'sm'"
        v-model:open="startReject"
        @closeModel="startReject = false"
        centered
        :okText="'Reject'"
        @ok="rejectItem()"
        width="400px"
      >
        <p>Are you sure you want to reject this request?</p>
      </Modal>
      <Modal
        :title="'Delete Request'"
        :size="'sm'"
        v-model:open="startDelete"
        @closeModel="startDelete = false"
        centered
        :okText="'Delete'"
        @ok="deleteItem()"
        width="400px"
      >
        <p>Are you sure you want to delete this request?</p>
      </Modal>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { Modal } from "ant-design-vue";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      allItems: [],
      newItem: {
        name: "",
        address: "",
        image: "",
      },
      selectedItem: null,
      startAdd: false,
      startDelete: false,
      startApprove: false,
      startReject: false,
      allItemsCount: 0,
      itemsPerpage: 10,
      pagesNumber: 0,
      currentPage: 1,
      searchQuery: {
        search: "",
        tenant_id: "",
      },
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_CANCELLATION_REQUESTS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            searchQuery: this.searchQuery,
            house_id: this.$getHouseId(),
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allItems = res.data.data;
              this.allItemsCount = res.data.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    approveItem() {
      this.$startLoader();
      this.$store
        .dispatch("APPROVE_CANCELLATION_REQUEST", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startApprove = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    rejectItem() {
      this.$startLoader();
      this.$store
        .dispatch("REJECT_CANCELLATION_REQUEST", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startReject = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_CANCELLATION_REQUEST", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
      }
    }
  }
}

table {
  margin-top: 2rem;
}
.table-filters {
  display: flex;
  justify-content: flex-start;
  column-gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  fieldset {
    width: 200px;
    border-radius: 5px;
    border: 1px solid #ccc;
    legend {
      font-size: 0.7rem;
      font-weight: bold;
      margin: 0 10px;
      color: #666;
      font-weight: 400;
      padding: 0 10px;
    }
    .form-group {
      padding: 0 !important;
      margin: 0 !important;
      select,
      input {
        width: 100%;
        padding: 0.5rem;
        border: none;
        border-radius: 5px;
        margin-bottom: 0;
        font-size: 0.75rem;
      }
    }
  }
  button {
    height: 50px;
    width: 150px;
    display: inline-block;
    background-color: $primary_color;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1rem;
    &:hover {
      background-color: $secondary_color;
    }
  }
}
</style>