import { BACKEND } from "../variables.js";
import axios from "axios";
const GET_TENANTS = BACKEND + "/tenants/getTenantsByHouse";
const GET_TENANT_BY_ID = BACKEND + "/tenants/getTenantsById";
const CREATE_TENANT = BACKEND + "/tenants/add";
const UPDATE_TENANT = BACKEND + "/tenants/update";
const DELETE_TENANT = BACKEND + "/tenants/delete";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_TENANTS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_TENANTS,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_TENANT_BY_ID"](state, payload) {
      return axios({
        method: "POST",
        url: GET_TENANT_BY_ID,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["CREATE_TENANT"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_TENANT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_TENANT"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_TENANT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_TENANT"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_TENANT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
};
