<template>
  <div id="NavBar">
    <TopBar />
    <div class="nav-bar">
      <router-link class="left" :to="{ name: 'Home' }">
        <img src="/img/krealty.png" alt="Cumbinn Logo" />
      </router-link>
      <div class="middle">
        <!-- links for real estate web  -->
        <ul class="nav-links">
          <li>
            <router-link to="/" class="nav-link">Home</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Properties' }" class="nav-link"
              >Property Listings</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'About' }" class="nav-link"
              >About Us</router-link
            >
          </li>
        </ul>
      </div>
      <div class="right">
        <router-link to="/contact" class="nav-link contact my-btn"
          >Contact Us</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
#NavBar {
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  .nav-bar {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    justify-content: space-between;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px 10rem;
    @media (max-width: 1500px) {
      padding: 10px 5rem;
    }
    @media (max-width: 1200px) {
      padding: 7px 4rem;
    }
    @media (max-width: 1000px) {
      padding: 5px 3rem;
    }
    @media (max-width: 750px) {
      padding: 5px 2rem;
    }
    @media (max-width: 500px) {
      padding: 5px 1rem;
    }
    @media (max-width: 900px) {
      display: none;
    }
    .middle,
    .right {
      .nav-links {
        display: flex;
        list-style: none;
        align-items: center;
        .nav-link {
          text-decoration: none;
          color: #000;
          margin: 0 10px;
          font-weight: 500;
          font-size: 14px;
          @media (max-width: 1500px) {
            font-size: 14px;
          }
          @media (max-width: 1200px) {
          }
          @media (max-width: 1000px) {
          }
          @media (max-width: 750px) {
          }
          @media (max-width: 500px) {
          }
          &:hover {
            color: $primary_color;
          }
        }
        .drop-item {
          position: relative;
          cursor: pointer;
          padding: 20px 16px;

          @media (max-width: 1500px) {
            padding: 10px 10px;
          }
          @media (max-width: 1200px) {
          }
          @media (max-width: 1000px) {
          }
          @media (max-width: 750px) {
          }
          @media (max-width: 500px) {
          }
          .drop-content {
            display: none;
            position: absolute;
            background-color: #fff;
            min-width: 160px;
            margin-top: 20px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            @media (max-width: 1500px) {
              margin-top: 10px;
            }
            @media (max-width: 1200px) {
            }
            @media (max-width: 1000px) {
            }
            @media (max-width: 750px) {
            }
            @media (max-width: 500px) {
            }
            .drop-link {
              color: $primary_color;
              padding: 12px 16px;
              text-decoration: none;
              display: block;
              @media (max-width: 1500px) {
                padding: 10px 10px;
                font-size: 12px;
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 1000px) {
              }
              @media (max-width: 750px) {
              }
              @media (max-width: 500px) {
              }
              &:hover {
                background-color: $primary_color;
                color: #fff;
                font-weight: 800;
              }
            }
          }
          &:hover .drop-content {
            display: block;
          }
        }
        .router-link-active,
        .router-link-exact-active {
          color: $primary_color;
          font-weight: 800;
        }
      }
    }
    .left {
      img {
        width: 150px;
        @media (max-width: 1500px) {
          width: 150px;
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
      }
    }
  }
}
</style>