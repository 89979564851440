<template>
  <div id="Footer" v-if="$store.state.sitesettings">
    <div class="top">
      <div class="left">
        <img src="/img/krealty.png" alt="logo" />
        <div class="footer-list">
          <ul>
            <li>
              {{ this.$store.state.sitesettings.site_address }}
            </li>
            <li>
              <i class="fas fa-phone-alt"></i>
              {{ this.$store.state.sitesettings.site_phone }}
            </li>
            <li>
              <i class="fas fa-envelope"></i>
              {{ this.$store.state.sitesettings.site_email }}
            </li>
          </ul>
        </div>
      </div>
      <div class="middle">
        <h3 class="title">QUICK LINKS</h3>
        <div class="footer-list">
          <ul>
            <li><router-link :to="{ name: 'Home' }">Home</router-link></li>
            <li>
              <router-link :to="{ name: 'Properties' }">Properties</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="middle-2">
        <h3 class="title">LOCATIONS</h3>
        <div class="footer-list">
          <ul>
            <li>
              <router-link :to="{ name: 'About' }">About K-Realty</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Contact' }">Contact Us</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <h3 class="title">FOLLOW US ON</h3>
        <div class="footer-list">
          <ul>
            <li>Stay up to date with the latest news and offers anytime</li>
            <li>
              <div class="socials">
                <a
                  :href="this.$store.state.sitesettings.site_facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                  v-if="this.$store.state.sitesettings.site_facebook"
                >
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a
                  :href="this.$store.state.sitesettings.site_instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                  v-if="this.$store.state.sitesettings.site_instagram"
                >
                  <i class="fab fa-instagram"></i>
                </a>
                <a
                  :href="this.$store.state.sitesettings.site_twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                  v-if="this.$store.state.sitesettings.site_twitter"
                >
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
                <a
                  :href="this.$store.state.sitesettings.site_linkedin"
                  target="_blank"
                  rel="noopener noreferrer"
                  v-if="this.$store.state.sitesettings.site_linkedin"
                >
                  <i class="fa-brands fa-linkedin-in"></i>
                </a>
                <a
                  :href="this.$store.state.sitesettings.site_youtube"
                  target="_blank"
                  rel="noopener noreferrer"
                  v-if="this.$store.state.sitesettings.site_youtube"
                >
                  <i class="fa-brands fa-youtube"></i>
                </a>
                <a
                  :href="this.$store.state.sitesettings.site_tiktok"
                  target="_blank"
                  rel="noopener noreferrer"
                  v-if="this.$store.state.sitesettings.site_tiktok"
                >
                  <i class="fa-brands fa-tiktok"></i>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
#Footer {
  width: 100%;
  background: #fff;
  color: #000;
  padding: 20px 0;
  border-top: 1px #eee solid;
  .top {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0 10%;
    gap: 1rem;
    margin-bottom: 3rem;
    padding-top: 3rem;
    ul {
      list-style: none;
      li {
        margin: 10px 0;
        color: #000;
      }
    }
    .left {
      img {
        width: 150px;
      }
      .footer-list {
      }
    }
    .middle,
    .middle-2,
    .right {
      .footer-list {
        width: 100%;
        a {
          color: #333;
          text-decoration: none;
          &:hover {
            color: $primary_color;
            font-weight: 600;
          }
        }
      }
    }
    .right {
      form {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;
        input {
          padding: 10px;
          border: none;
          border-radius: 5px 0px 0px 5px;
          border: 1px solid $secondary_color;
        }
        button {
          padding: 10px 20px;
          border: none;
          border-radius: 0px 5px 5px 0px;
          background-color: $secondary_color;
          color: #000;
          cursor: pointer;
        }
      }
      .socials {
        display: flex;
        gap: 10px;
        i {
          margin: 0 5px;
          font-size: 25px;
          color: $primary_color;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
          &.fa-x-twitter {
            &:hover {
              color: #1da1f2;
            }
          }
          &.fa-linkedin-in {
            &:hover {
              color: #0a66c2;
            }
          }
          &.fa-instagram {
            &:hover {
              color: #e1306c;
            }
          }
          &.fa-facebook-f {
            &:hover {
              color: #3b5998;
            }
          }
          &.fa-tiktok {
            &:hover {
              color: #000;
            }
          }
          &.fa-youtube {
            &:hover {
              color: #ff0000;
            }
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    padding: 0 10%;
    width: 100%;
    .left {
      p {
        font-size: 12px;
      }
    }
    .right {
      ul {
        list-style: none;
        li {
          display: inline;
          margin: 0 10px;
          a {
            color: #fff;
            text-decoration: none;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>