<template>
  <div class="kanban-card-modal" v-if="selectedCard">
    <div class="card-modal-left" @click="clearAllPopups('none')">
      <div class="card-title">
        <div class="icon">
          <i class="fa fa-circle"></i>
        </div>
        <div class="content">
          <h3>{{ selectedCard.title }}</h3>
          <p>
            In list <u>{{ getStatusName(selectedCard.status) }}</u>
          </p>
          <div class="dflex align-center gap-30">
            <div class="priority">
              <p>Priority:</p>
              <div class="add-priority-button" :class="selectedCard.priority">
                <i class="fa fa-flag"></i> {{ selectedCard.priority }}
              </div>
            </div>
            <div class="sender">
              <p>Sent by:</p>
              <div class="sender-content">
                <img
                  :src="$upload(selectedCard.tenant.tenant_image)"
                  :alt="selectedCard.tenant.tenant_name"
                />
                <span>{{ selectedCard.tenant.tenant_name }}</span>
              </div>
            </div>
            <div class="assignees">
              <p>Assigned to:</p>
              <div class="assignees-content">
                <a-tooltip
                  :title="assignee.name"
                  v-for="assignee in selectedCard.assignees"
                  :key="assignee.admin_id"
                >
                  <img :src="$upload(assignee.image)" @error="$imageurlalt()" />
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-description">
        <div class="icon">
          <i class="fa fa-align-left"></i>
        </div>
        <div class="content">
          <h4>Description</h4>
          <p>{{ selectedCard.description }}</p>
        </div>
      </div>
      <div class="card-attachments">
        <div class="icon">
          <i class="fa fa-paperclip"></i>
        </div>
        <div class="content">
          <div class="flex-between">
            <h4>Attachments</h4>
            <a-button @click="startUpload = true"> Add </a-button>
          </div>
          <o-upload
            :multiple="true"
            :text="'Drag and drop files here or click to upload'"
            :size="'sm'"
            @imagesUploaded="
              (data) => {
                fileList = data;
              }
            "
            @imagesUploadedFailed="
              (data) => {
                console.log('failed');
              }
            "
            :value="fileList"
            @startLoader="() => $startLoader()"
            @stopLoader="() => $stopLoader()"
            v-if="startUpload"
            style="margin-top: 1rem"
          />
          <a-button
            v-if="startUpload && fileList.length > 0"
            style="margin-top: 10px"
            @click="saveAttachments"
            >save attachments</a-button
          >
          <div class="attachments">
            <div
              class="attachment"
              v-for="attachment in selectedCard.attachments"
              :key="attachment.attachment_id"
            >
              <div class="left">
                <img
                  :src="$upload(attachment.attachment)"
                  :alt="attachment.attachment_type"
                  @click="openAttachment(attachment.attachment)"
                />
                <div class="text">
                  <span v-if="attachment.org_name">{{
                    attachment.org_name
                  }}</span>
                  <span v-else>{{ attachment.attachment_type }}</span>
                  <p>Added {{ $ago(attachment.created_at) }}</p>
                </div>
              </div>
              <button @click="deleteAttachment(attachment.attachment_id)">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-comments">
        <div class="icon">
          <i class="fa fa-comments"></i>
        </div>
        <div class="content">
          <div class="flex-between">
            <h4>Comments</h4>
            <!-- <a-button>Add</a-button> -->
          </div>
          <div class="add-comment">
            <div class="top">
              <img :src="$img('avatar.png')" alt="User" />
              <a-textarea
                placeholder="Write a comment..."
                auto-size="{ minRows: 1, maxRows: 6 }"
                v-model:value="comment"
              ></a-textarea>
            </div>
            <div class="save" v-if="comment && comment.trim().length > 0">
              <button class="my-btn sm" @click.prevent="addComment()">
                Save
              </button>
            </div>
          </div>
          <div class="comments">
            <div
              class="comment"
              v-for="comment in selectedCard.comments"
              :key="comment.comment_id"
            >
              <div class="comment-header">
                <img
                  :src="$upload(comment.user.image)"
                  :alt="comment.user.name"
                  v-if="comment.user_type == 'admin'"
                />
                <img
                  :src="$upload(comment.user.tenant_image)"
                  :alt="comment.user.tenant_name"
                  v-else
                />
                <span v-if="comment.user_type == 'admin'">{{
                  comment.user.name
                }}</span>
                <span v-else>{{ comment.user.tenant_name }}</span>
                <span class="date">{{ $ago(comment.created_at) }}</span>
              </div>
              <div class="comment-body">
                <p>{{ comment.comment }}</p>
              </div>
              <div
                class="comment-footer"
                v-if="comment.user.id == $getAdmin().id"
              >
                <button @click="deleteComment(comment.comment_id)">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-modal-right">
      <div class="add-to-card">
        <div class="title">
          <h4>Add to card</h4>
        </div>
        <div class="content">
          <div class="add-members">
            <div class="add-member-button" @click="clearAllPopups('member')">
              <i class="fa fa-user"></i> Members
            </div>
            <div class="members-list" v-if="showAddMembers">
              <div class="list-title">
                <h5>Members</h5>
              </div>
              <div class="list-content">
                <div class="search-member">
                  <a-input placeholder="Search members..." allow-clear />
                </div>
                <div class="member-ul">
                  <div
                    class="member"
                    v-for="assignee in assignees"
                    :key="assignee.id"
                  >
                    <img :src="$img('avatar.png')" :alt="assignee.name" />
                    <span>{{ assignee.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="add-priority">
            <div
              class="add-priority-button"
              @click="clearAllPopups('priority')"
            >
              <i class="fa fa-flag"></i> Priority
            </div>

            <div class="priority-list" v-if="showAddPriority">
              <div class="list-title">
                <h5 :class="selectedCard.priority">Priority</h5>
              </div>
              <div class="list-content">
                <div class="priority-ul">
                  <div class="priority low">
                    Low
                    <i
                      class="fa fa-check"
                      v-if="selectedCard.priority == 'low'"
                    ></i>
                  </div>
                  <div class="priority medium">
                    Medium
                    <i
                      class="fa fa-check"
                      v-if="selectedCard.priority == 'medium'"
                    ></i>
                  </div>
                  <div class="priority high">
                    High
                    <i
                      class="fa fa-check"
                      v-if="selectedCard.priority == 'high'"
                    ></i>
                  </div>
                  <div class="priority critical">
                    Critical
                    <i
                      class="fa fa-check"
                      v-if="selectedCard.priority == 'critical'"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="title">
            <h4>Actions</h4>
          </div>
          <div class="change-status">
            <div class="change-status-button" @click="clearAllPopups('status')">
              <i class="fa fa-exchange"></i> Move
            </div>

            <div class="status-list" v-if="showAddStatus">
              <div class="list-title">
                <h5>Lists</h5>
              </div>
              <div class="list-content">
                <div class="status-ul">
                  <div class="status backlog">
                    Backlog
                    <i
                      class="fa fa-check"
                      v-if="selectedCard.status == 'backlog'"
                    ></i>
                  </div>
                  <div class="status assigned">
                    Assigned
                    <i
                      class="fa fa-check"
                      v-if="selectedCard.status == 'assigned'"
                    ></i>
                  </div>
                  <div class="status inProgress">
                    In Progress
                    <i
                      class="fa fa-check"
                      v-if="selectedCard.status == 'inProgress'"
                    ></i>
                  </div>
                  <div class="status inReview">
                    In Review
                    <i
                      class="fa fa-check"
                      v-if="selectedCard.status == 'inReview'"
                    ></i>
                  </div>
                  <div class="status completed">
                    Completed
                    <i
                      class="fa fa-check"
                      v-if="selectedCard.status == 'completed'"
                    ></i>
                  </div>
                  <div class="status archived">
                    Archived
                    <i
                      class="fa fa-check"
                      v-if="selectedCard.status == 'archived'"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedCard: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      assignees: [
        {
          id: 1,
          name: "John Doe",
          image: null,
        },
        {
          id: 2,
          name: "Jane Doe",
          image: null,
        },
        {
          id: 3,
          name: "John Smith",
          image: null,
        },
      ],
      showAddMembers: false,
      showAddPriority: false,
      showAddStatus: false,
      comment: "",
      fileList: [],
      startUpload: false,
    };
  },
  methods: {
    getStatusName(status) {
      switch (status) {
        case "backlog":
          return "Backlog";
        case "assigned":
          return "Assigned";
        case "inProgress":
          return "In Progress";
        case "inReview":
          return "In Review";
        case "completed":
          return "Completed";
        case "archived":
          return "Archived";
        default:
          return "";
      }
    },
    clearAllPopups(expept) {
      if (expept == "none") {
        this.showAddMembers = false;
        this.showAddPriority = false;
        this.showAddStatus = false;
      } else {
        switch (expept) {
          case "member":
            this.showAddMembers = !this.showAddMembers;
            this.showAddPriority = false;
            this.showAddStatus = false;
            break;
          case "priority":
            this.showAddPriority = !this.showAddPriority;
            this.showAddMembers = false;
            this.showAddStatus = false;
            break;
          case "status":
            this.showAddStatus = !this.showAddStatus;
            this.showAddMembers = false;
            this.showAddPriority = false;
            break;
          default:
            break;
        }
      }
    },
    openAttachment(attachment) {
      window.open(this.$upload(attachment), "_blank");
    },
    addComment() {
      this.$emit("add-comment", this.comment);
      this.comment = "";
    },
    deleteComment(comment) {
      this.$emit("delete-comment", comment);
    },
    deleteAttachment(attachment) {
      this.$emit("delete-attachment", attachment);
    },
    saveAttachments() {
      if (this.fileList.length > 0) {
        this.$emit("add-attachments", this.fileList);
      }
    },
  },
  mounted() {
    this.clearAllPopups("none");
  },
  watch: {
    selectedCard() {
      this.clearAllPopups("none");
      this.comment = "";
      this.fileList = [];
      this.startUpload = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.kanban-card-modal {
  display: grid;
  grid-template-columns: 1fr 220px;
  .card-modal-left {
    background-color: white;
    padding: 20px;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .card-title {
      display: flex;
      align-items: flex-start;
      .icon {
        margin-right: 10px;
        margin-top: 8px;
        i {
          font-size: 20px;
          color: $primary_color;
        }
      }
      .content {
        width: 100%;
        h3 {
          font-size: 20px;
          margin: 0;
        }
        p {
          margin: 0;
          u {
            font-weight: bold;
          }
        }
        .sender {
          margin-top: 1rem;
          p {
            margin: 0;
            line-height: 2.5;
          }
          .sender-content {
            display: flex;
            align-items: center;
            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              margin-right: 10px;
              object-fit: contain;
            }
            span {
              font-weight: bold;
            }
          }
        }
        .assignees {
          margin-top: 1rem;
          p {
            margin: 0;
            line-height: 2.5;
          }
          .assignees-content {
            display: flex;
            align-items: center;
            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              margin-right: 10px;
              object-fit: cover;
            }
          }
        }
        .priority {
          margin-top: 1rem;
          p {
            margin: 0;
            line-height: 2.5;
          }
          .add-priority-button {
            background-color: #eee;
            color: black;
            border: none;
            padding: 5px 15px;
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            gap: 10px;
            align-items: center;
            font-weight: 500;
            &.low {
              background-color: #00b894;
              color: white;
            }
            &.medium {
              background-color: #d9ff00;
              color: black;
            }
            &.high {
              background-color: #ff8c00;
              color: white;
            }
            &.critical {
              background-color: #c50202;
              color: white;
            }
          }
        }
      }
    }
    .card-description {
      margin-top: 20px;
      display: flex;
      .icon {
        margin-right: 10px;
        margin-top: 5px;
        i {
          font-size: 20px;
          color: $primary_color;
        }
      }
      .content {
        h4 {
          font-size: 16px;
          margin: 0;
        }
        p {
          margin: 0;
          line-height: 1.5;
          margin-top: 10px;
        }
      }
    }
    .card-attachments {
      margin-top: 20px;
      display: flex;
      .icon {
        margin-right: 10px;
        margin-top: 5px;
        i {
          font-size: 20px;
          color: $primary_color;
        }
      }
      .content {
        width: 100%;
        .flex-between {
          h4 {
            font-size: 16px;
            margin: 0;
          }
          button {
            background-color: #eee;
            color: black;
            border: none;
            padding: 5px 15px;
            border-radius: 5px;
            cursor: pointer;
          }
        }
        .attachments {
          display: flex;
          flex-direction: column;
          .attachment {
            margin-top: 10px;
            margin-right: 10px;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            &:hover {
              background-color: #eee;
              cursor: pointer;
            }
            .left {
              display: flex;
              align-items: center;
              width: 100%;
              gap: 10px;
              img {
                width: 100px;
                height: 50px;
                object-fit: contain;
                border-radius: 5px;
              }
              .text {
                span {
                  font-weight: bold;
                }
                p {
                  font-size: 12px;
                  color: #888;
                  margin: 0;
                }
              }
            }
            button {
              color: black;
              border: none;
              padding: 5px 15px;
              border-radius: 5px;
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .card-comments {
      margin-top: 20px;
      display: flex;
      .icon {
        margin-right: 10px;
        margin-top: 5px;
        i {
          font-size: 20px;
          color: $primary_color;
        }
      }
      .content {
        width: 100%;
        .flex-between {
          h4 {
            font-size: 16px;
            margin: 0;
          }
          button {
            background-color: #eee;
            color: black;
            border: none;
            padding: 5px 15px;
            border-radius: 5px;
            cursor: pointer;
          }
        }
        .add-comment {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .top {
            display: flex;
            gap: 10px;
            margin-top: 10px;
            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
        }
        .comments {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .comment {
            margin-top: 10px;
            .comment-header {
              display: flex;
              align-items: center;
              img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 10px;
                object-fit: cover;
              }
              span {
                font-weight: bold;
                margin-right: 10px;
                &.date {
                  color: #888;
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
            .comment-body {
              background-color: #f9f9f9;
              padding: 10px;
              margin-top: 10px;
              border: 1px solid #eee;
              border-radius: 5px;
              p {
                margin: 0;
                line-height: 1.5;
              }
            }
            .comment-footer {
              display: flex;
              gap: 10px;
              padding-top: 5px;
              button {
                background-color: none;
                padding: none;
                border: none;
                cursor: pointer;
                color: #00184d;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
  .card-modal-right {
    background-color: white;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .add-to-card {
      .title {
        margin-bottom: 0.3rem;
        h4 {
          font-size: 12px;
          margin: 0;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .add-members {
          position: relative;
          .add-member-button {
            background-color: #eee;
            color: black;
            border: none;
            padding: 5px 15px;
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            gap: 10px;
            align-items: center;
            font-weight: 500;
          }
          .members-list {
            padding: 10px 1rem;
            border: 1px solid #eee;
            border-radius: 5px;
            position: absolute;
            top: 110%;
            right: 0;
            background-color: white;
            z-index: 2;
            width: 250px;

            .list-title {
              h5 {
                font-size: 16px;
                margin: 0;
              }
            }
            .list-content {
              .search-member {
                margin-top: 10px;
              }
              .member-ul {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                .member {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  cursor: pointer;
                  padding: 5px;
                  border-radius: 5px;
                  &:hover {
                    background-color: #eeeeee;
                  }
                  img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    object-fit: contain;
                  }
                }
              }
            }
          }
        }
        .add-priority {
          position: relative;
          .add-priority-button {
            background-color: #eee;
            color: black;
            border: none;
            padding: 5px 15px;
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            gap: 10px;
            align-items: center;
            font-weight: 500;
            &.low {
              background-color: #00b894;
              color: white;
            }
            &.medium {
              background-color: #d9ff00;
              color: black;
            }
            &.high {
              background-color: #ff8c00;
              color: white;
            }
            &.critical {
              background-color: #c50202;
              color: white;
            }
          }
          .priority-list {
            padding: 10px 1rem;
            border: 1px solid #eee;
            border-radius: 5px;
            position: absolute;
            top: 110%;
            right: 0;
            background-color: white;
            z-index: 2;
            width: 250px;

            .list-title {
              h5 {
                font-size: 16px;
                margin: 0;
                margin-bottom: 5px;
              }
            }
            .list-content {
              .priority-ul {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .priority {
                  padding: 5px 0.6rem;
                  border-radius: 5px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  &.low {
                    background-color: #00b894;
                    color: white;
                  }
                  &.medium {
                    background-color: #d9ff00;
                    color: black;
                  }
                  &.high {
                    background-color: #ff8c00;
                    color: white;
                  }
                  &.critical {
                    background-color: #c50202;
                    color: white;
                  }
                }
              }
            }
          }
        }
        .change-status {
          position: relative;
          .change-status-button {
            background-color: #eee;
            color: black;
            border: none;
            padding: 5px 15px;
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            gap: 10px;
            align-items: center;
            font-weight: 500;
          }
          .status-list {
            padding: 10px 1rem;
            border: 1px solid #eee;
            border-radius: 5px;
            position: absolute;
            top: 110%;
            right: 0;
            background-color: white;
            z-index: 2;
            width: 250px;

            .list-title {
              h5 {
                font-size: 16px;
                margin: 0;
                margin-bottom: 5px;
              }
            }
            .list-content {
              .status-ul {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .status {
                  padding: 5px 0.6rem;
                  border-radius: 5px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  &.backlog {
                    background-color: #f9f9f9;
                    color: black;
                  }
                  &.assigned {
                    background-color: #f9f9f9;
                    color: black;
                  }
                  &.inProgress {
                    background-color: #f9f9f9;
                    color: black;
                  }
                  &.inReview {
                    background-color: #f9f9f9;
                    color: black;
                  }
                  &.completed {
                    background-color: #f9f9f9;
                    color: black;
                  }
                  &.archived {
                    background-color: #f9f9f9;
                    color: black;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>