import { BACKEND } from "../variables.js";
import axios from "axios";
const GET_FLOORS_BY_HOUSE_ID = BACKEND + "/floor/getFloorsByHouseId";
const GET_FLOORS_BY_WING_ID = BACKEND + "/floor/getFloorsByWingId";
const CREATE_FLOOR = BACKEND + "/floor/createFloor";
const UPDATE_FLOOR = BACKEND + "/floor/updateFloor";
const DELETE_FLOOR = BACKEND + "/floor/deleteFloor";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_FLOORS_BY_HOUSE_ID"](state, payload) {
      return axios({
        method: "POST",
        url: GET_FLOORS_BY_HOUSE_ID + `?page=${payload.data.page}`,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_FLOORS_BY_WING_ID"](state, payload) {
      return axios({
        method: "POST",
        url: GET_FLOORS_BY_WING_ID + `?page=${payload.data.page}`,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["CREATE_FLOOR"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_FLOOR,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_FLOOR"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_FLOOR,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_FLOOR"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_FLOOR,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
};
