import { BACKEND } from "../variables.js";
import axios from "axios";
const GET_CANCELLATION_REQUESTS =
  BACKEND + "/cancellationRequests/getAllByHouse";
const GET_CANCELLATION_REQUESTS_BY_TENANT_ID =
  BACKEND + "/cancellationRequests/getAllByTenantId";
const GET_MY_CANCELLATION_REQUESTS =
  BACKEND + "/cancellationRequests/getMyRequests";
const CREATE_CANCELLATION_REQUEST = BACKEND + "/cancellationRequests/add";
const APPROVE_CANCELLATION_REQUEST = BACKEND + "/cancellationRequests/approve";
const REJECT_CANCELLATION_REQUEST = BACKEND + "/cancellationRequests/reject";
const DELETE_CANCELLATION_REQUEST = BACKEND + "/cancellationRequests/delete";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_CANCELLATION_REQUESTS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_CANCELLATION_REQUESTS,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_CANCELLATION_REQUESTS_BY_TENANT_ID"](state, payload) {
      return axios({
        method: "POST",
        url: GET_CANCELLATION_REQUESTS_BY_TENANT_ID,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_MY_CANCELLATION_REQUESTS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_MY_CANCELLATION_REQUESTS,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["CREATE_CANCELLATION_REQUEST"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_CANCELLATION_REQUEST,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["APPROVE_CANCELLATION_REQUEST"](state, payload) {
      return axios({
        method: "POST",
        url: APPROVE_CANCELLATION_REQUEST,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["REJECT_CANCELLATION_REQUEST"](state, payload) {
      return axios({
        method: "POST",
        url: REJECT_CANCELLATION_REQUEST,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_CANCELLATION_REQUEST"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_CANCELLATION_REQUEST,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
};
