<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span>Add Employee</span>
        <p>Fill the form below to add a new employee.</p>
      </h1>
      <div class="admin-forms">
        <div class="input-group group-2">
          <div class="form-group">
            <label for="names">Names</label>
            <input
              type="text"
              id="names"
              v-model="newItem.names"
              placeholder="Names"
            />
          </div>
          <div class="form-group">
            <label for="Nid">National ID</label>
            <input
              type="text"
              id="company"
              v-model="newItem.nid"
              placeholder="Identity"
            />
          </div>
        </div>
        <div class="input-group group-2">
          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="email"
              id="email"
              v-model="newItem.email"
              placeholder="Email"
            />
          </div>
          <div class="form-group">
            <label for="phone">Phone</label>
            <input
              type="text"
              id="phone"
              v-model="newItem.phone"
              placeholder="Phone"
            />
          </div>
        </div>
        <div class="input-group group-2">
          <div class="form-group">
            <label for="type">Type</label>
            <select id="type" v-model="newItem.type">
              <option value="">Select Type</option>
              <option>Director</option>
              <option>Manager</option>
              <option>Finance</option>
              <option>Officer</option>
              <option>Lawyer</option>
            </select>
          </div>
          <div class="form-group">
            <label for="status">Status</label>
            <select id="status" v-model="newItem.status">
              <option value="">Select Status</option>
              <option value="Active">Active</option>
              <option value="On Hold">On Hold</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
        </div>
        <div class="input-group">
          <div class="form-group">
            <label for="image">Image</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  newItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="newItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
        </div>
        <div class="input-group">
          <div class="form-group">
            <label for="other Info">Other Info</label>
            <textarea
              id="other Info"
              v-model="newItem.otherInfo"
              placeholder="Other Info"
            ></textarea>
          </div>
        </div>
        <button @click="createItem">Submit</button>
      </div>
      <confirmModel
        v-if="startDelete"
        @closeModel="startDelete = false"
        :modelTitle="'Archive Tenant'"
        :size="'sm'"
        :displayText="'Are you sure you want to move this tenant to archive?'"
        @confirmModel="deleteItem"
      />
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      tenants: [],
      types: ["Company", "Individual", "Reseller"],
      rooms: [
        {
          id: 1,
          name: "Room 1",
          description: "Room 1",
          number: "F1-01",
          wing: "Wing A",
          price: 1000,
        },
        {
          id: 2,
          name: "Room 2",
          description: "Room 2",
          number: "F1-02",
          wing: "Wing A",
          price: 2000,
        },
        {
          id: 3,
          name: "Room 3",
          description: "Room 3",
          number: "F1-03",
          wing: "Wing A",
          price: 3000,
        },
        {
          id: 4,
          name: "Room 4",
          description: "Room 4",
          number: "F1-04",
          wing: "Wing A",
          price: 4000,
        },
        {
          id: 5,
          name: "Room 5",
          description: "Room 5",
          number: "F1-05",
          wing: "Wing A",
          price: 5000,
        },
      ],
      wings: ["Wing A", "Wing B", "Wing C", "Wing D"],
      subscriptions: [
        {
          id: 1,
          plan: "Daily",
          status: "Active",
          start: "2020-01-01",
          end: "2020-12-31",
        },
        {
          id: 2,
          plan: "Weekly",
          status: "Active",
          start: "2020-01-01",
          end: "2020-12-31",
        },
        {
          id: 3,
          plan: "Monthly",
          status: "Active",
          start: "2020-01-01",
          end: "2020-12-31",
        },
        {
          id: 4,
          plan: "Quarterly",
          status: "Active",
          start: "2020-01-01",
          end: "2020-12-31",
        },
        {
          id: 5,
          plan: "Yearly",
          status: "Active",
          start: "2020-01-01",
          end: "2020-12-31",
        },
      ],
      newItem: {
        leader: "",
        company: "",
        email: "",
        phone: "",
        type: "",
        rooms: [],
        wing: "",
        status: "",
        joined_at: "",
        image: null,
        subscription: "",
        companyEquipments: [],
        unitEquipments: [],
        subscription_count: 1,
      },
      startAdd: false,
      selected: null,
    };
  },
  methods: {
    createItem() {
      if (this.newItem.name && this.newItem.address && this.newItem.image) {
        this.$startLoader();
        this.$store
          .dispatch("CREATE_LOCATION", {
            data: this.newItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    removecompanyEquipments(item) {
      this.newItem.companyEquipments.splice(
        this.newItem.companyEquipments.indexOf(item),
        1
      );
    },
    removeunitEquipments(item) {
      this.newItem.unitEquipments.splice(
        this.newItem.unitEquipments.indexOf(item),
        1
      );
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 1rem;
    p {
      font-size: 14px;
      color: #686868;
      font-weight: 400;
    }
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
      }
    }
  }
}
</style>