import { BACKEND } from "../variables.js";
import axios from "axios";
const GET_ROOMS = BACKEND + "/room/getRoomsByHouse";
const GET_ROOMS_BY_WING = BACKEND + "/room/getRoomsByWing";
const GET_ROOMS_BY_FLOOR = BACKEND + "/room/getRoomsByFloor";
const GET_ROOMS_BY_ROOM_CATEGORY = BACKEND + "/room/getRoomsByRoomCategory";
const CREATE_ROOM = BACKEND + "/room/createRoom";
const UPDATE_ROOM = BACKEND + "/room/updateRoom";
const DELETE_ROOM = BACKEND + "/room/deleteRoom";

export default {
  state: {
    rooms: [],
  },
  getters: {},
  mutations: {},
  actions: {
    ["GET_ROOMS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ROOMS + `?page=${payload.data.page}`,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_ROOMS_BY_WING"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ROOMS_BY_WING + `?page=${payload.data.page}`,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_ROOMS_BY_FLOOR"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ROOMS_BY_FLOOR + `?page=${payload.data.page}`,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_ROOMS_BY_ROOM_CATEGORY"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ROOMS_BY_ROOM_CATEGORY + `?page=${payload.data.page}`,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["CREATE_ROOM"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_ROOM,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_ROOM"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_ROOM,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_ROOM"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_ROOM,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
};
